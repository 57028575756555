<template>
  <edit-item-component endpoint="ideas" type="idea"/>
</template>

<script>
export default {
  name: "Edit"
}
</script>

<style scoped>

</style>